import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
// import "./i18n";
import * as serviceWorker from './serviceWorker';
// import AppProvider from "./app/AppProvider/AppProvider";
import store from './redux/store';
import {Provider} from 'react-redux';

ReactDOM.render(
  <Provider store={store}>
      <BrowserRouter basename="/eduzo-admin">
        <App />
      </BrowserRouter>
  </Provider>
  , document.getElementById('root'));

serviceWorker.unregister();