import React, { Component,Suspense, lazy ,useEffect } from 'react';
import { Switch, Route, Redirect , Router} from 'react-router-dom';
import PropTypes from "prop-types";
import {loadUser} from '../redux/actions/AuthActions'
import store from '../redux/store';
import { connect } from "react-redux";
import PrivateRoute from './PrivateRoute/PrivateRoute';
import Spinner from '../app/shared/Spinner';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const Insights = lazy(() => import('./insights/Insights'));
const Games = lazy(() => import('./Games/Games'));
const ViewGame = lazy(() => import('./Games/ViewGame'));
const AddGame = lazy(() => import('./Games/AddGame'));
const EditGame = lazy(() => import('./Games/EditGame'));
const Institutes = lazy(() => import('./Institutes/Institutes'));
const ViewInstitutes = lazy(() => import('./Institutes/ViewInstitute'));
const EditInstitutes = lazy(() => import('./Institutes/EditInstitute'));
const Notifications = lazy(() => import('./Institutes/Notifications'));
const AddInstitutes = lazy(() => import('./Institutes/AddInstitute'));
const EditInstituteUser = lazy(() => import('./Institutes/EditInstituteUser'));
const AddInstituteInstructors = lazy(() => import('./Institutes/AddInstituteInstructors'));

const ViewInstituteGame = lazy(() => import('./Institutes/ViewInstituteGame'));



const Leads = lazy(() => import('./insights/Leads'));
const Report = lazy(() => import('./insights/Report'));


const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));

const BasicElements = lazy(() => import('./form-elements/BasicElements'));

const BasicTable = lazy(() => import('./tables/BasicTable'));

const Mdi = lazy(() => import('./icons/Mdi'));

const ChartJs = lazy(() => import('./charts/ChartJs'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));
const Register = lazy(() => import('./user-pages/Register'));
const AddInstituteUser = lazy(() => import('./Institutes/AddInstituteUser'));



const AppRoutes = () =>{
  // const AppRoutes = ({  auth: { isAuthenticated, loading },   component: Component,}) =>{
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  //  console.log(isAuthenticated)

    return (

      <Suspense fallback={<Spinner/>}>
        <Switch>
          <PrivateRoute exact path="/insights" component={ Insights } />
          <PrivateRoute exact path="/insights/leads" component={ Leads } />
          <PrivateRoute exact path="/games" component={ Games } />
          <PrivateRoute exact path="/games/viewgame/:id" component={ ViewGame } />
          <PrivateRoute exact path="/games/addgame" component={ AddGame } />
          <PrivateRoute exact path="/games/editgame/:id" component={ EditGame } />
          <PrivateRoute exact path="/institutes" component={ Institutes } />
          <PrivateRoute exact path="/institutes/notifications" component={ Notifications } />
          <PrivateRoute exact path="/institutes/viewinstitute/:id" component={ ViewInstitutes } />
          <PrivateRoute exact path="/institutes/editinstitute/:id" component={ EditInstitutes } />
          <PrivateRoute exact path="/institutes/:id/game" component={ ViewInstituteGame } />
          <PrivateRoute exact path="/institutes/addinstitute" component={ AddInstitutes } />
          <PrivateRoute exact path="/institutes/editinstitute/adduser/:id" component={ AddInstituteUser } />
          <PrivateRoute exact path="/institutes/editinstitute/addInstructors/:id" component={ AddInstituteInstructors } />
          <PrivateRoute exact path="/institutes/editinstitute/edituser/:institute_id/:id" component={ EditInstituteUser } />
          <PrivateRoute exact path="/insights" component={ Institutes } />
          
          
          <PrivateRoute exact path="/dashboard" component={ Dashboard } />

          <PrivateRoute path="/basic-ui/buttons" component={ Buttons } />
          <PrivateRoute path="/basic-ui/dropdowns" component={ Dropdowns } />

          <PrivateRoute path="/form-Elements/basic-elements" component={ BasicElements } />

          <PrivateRoute path="/tables" component={ BasicTable } />
          <PrivateRoute path="/report" component={ Report } />


          <PrivateRoute path="/icons/mdi" component={ Mdi } />

          <PrivateRoute path="/charts/chart-js" component={ ChartJs } />


          <Route path="/login" component={ Login } />
          {/* <Route path="/register" component={ Register } /> */}

          <PrivateRoute path="/error-pages/error-404" component={ Error404 } />
          <PrivateRoute path="/error-pages/error-500" component={ Error500 } />

          <Redirect to="/login" />
 
        </Switch> 
      </Suspense>
    );
}

export default AppRoutes