import * as types from '../types';

const initialState = {
    loading: false,
    error: false,
    fetchLeadsError: null,
}

export const leadReducer = (state = initialState, action) => {
    switch (action.type) {

        case types.STORE_LEADS:
            return {
                ...state,
                leads: action.payload
            }

        case types.CREATE_LEAD:
            return {
                ...state,
                leads: state.leads ? [...state.leads, action.payload] : undefined
            }

        case types.UPDATE_LEAD:
            return {
                ...state,
                // eslint-disable-next-line
                leads: state.leads ? state.leads.map(u => u.id === +action.payload.id ? action.payload : u) : undefined
            }

        case types.DELETE_LEAD:
            return {
                ...state,
                leads: state.leads.filter(u => u.id !== +action.payload)
            }

        case types.LEAD_FETCH_ERROR:
            return {
                ...state,
                fetchLeadError: action.payload
            }
        default:
            return state;
    }
}