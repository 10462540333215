import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import {logout} from '../../redux/actions/AuthActions'
import { connect } from 'react-redux';
import { ReactComponent as AllGamesIcon} from '../../assets/images/AllGamesIcon.svg';
import { ReactComponent as AllInstitutesIcon} from '../../assets/images/InstitutesIcon.svg';
import { ReactComponent as InsightsIcon} from '../../assets/images/InsigntsIcon.svg';

// import './sidebar.css' ;



class Sidebar extends React.Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({[menuState] : false});
    } else if(Object.keys(this.state).length === 0) {
      this.setState({[menuState] : true});
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({[i]: false});
      });
      this.setState({[menuState] : true});
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({[i]: false});
    });

    const dropdownPaths = [
      {path:'/apps', state: 'appsMenuOpen'},
      {path:'/basic-ui', state: 'basicUiMenuOpen'},
      {path:'/form-elements', state: 'formElementsMenuOpen'},
      {path:'/tables', state: 'tablesMenuOpen'},
      {path:'/icons', state: 'iconsMenuOpen'},
      {path:'/charts', state: 'chartsMenuOpen'},
      {path:'/user-pages', state: 'userPagesMenuOpen'},
      {path:'/error-pages', state: 'errorPagesMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({[obj.state] : true})
      }
    }));
 
  } 
  render () {

    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
          <p className="sidebar-brand brand-logo">Labs Backend</p>
        </div>
        <ul className="nav">
          <li className={ this.isPathActive('/games') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/games">
              <AllGamesIcon className="sidebarIcon"/>
              <span className="menu-title" data-><Trans> All Games</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/institutes') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/institutes">
              <AllInstitutesIcon className="sidebarIcon"/>
              <span className="menu-title"><Trans>Institutes</Trans></span>
            </Link>
          </li>
          <li className={ this.isPathActive('/insights') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/insights">
            <InsightsIcon className="sidebarIcon"/>
              <span className="menu-title"><Trans>Insights</Trans></span>
            </Link>
          </li>
          <li onClick={this.props.handleLogout} className={ this.isPathActive('/logout') ? 'nav-item active' : 'nav-item' } style={{marginTop:'370px', fontWeight : "600 !important"}}>
            <Link >
              <button style={{marginLeft : '3vw', fontWeight : "600", fontSize : '20px', padding : "10px 0px", letterSpacing :'0.5px'}} className="btn btn-md">Logout</button>
            </Link>
          </li>        
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

}

function mapActionToProps(dispatch){
  return{
    handleLogout : function(){
          dispatch(logout())
      }
  }
}

export default withRouter(connect(null, mapActionToProps)(Sidebar));