import * as types from '../types';

const initialState = {
    loading: false,
    error: false,
    fetchInstitutesError: null,
}

export const instituteReducer = (state = initialState, action) => {
    switch (action.type) {

        case types.STORE_INSTITUTES:
            return {
                ...state,
                institutes: action.payload
            }

        case types.CREATE_INSTITUTE:
            return {
                ...state,
                institutes: state.institutes ? [...state.institutes, action.payload] : undefined
            }
        case types.CREATE_USER:
            return {
                ...state,
                users: state.users ? [...state.users, action.payload] : undefined
            }

        case types.UPDATE_INSTITUTE:
            return {
                ...state,
                // eslint-disable-next-line
                institutes: state.institutes ? state.institutes.map(u => u.id === +action.payload.id ? action.payload : u) : undefined
            }

        case types.DELETE_INSTITUTE:
            return {
                ...state,
                institutes: state.institutes.filter(u => u.id !== +action.payload)
            }

        case types.INSTITUTE_FETCH_ERROR:
            return {
                ...state,
                fetchInstituteError: action.payload
            }
        default:
            return state;
    }
}