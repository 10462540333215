//Common types
export const NOT_LOADING = 'NOT_LOADING';
export const IS_LOADING = 'IS_LOADING';

//Admin types
export const STORE_ADMIN_DATA = 'STORE_ADMIN_DATA';
export const DESTROY_ADMIN_DATA = 'DESTROY_ADMIN_DATA';
export const ADMIN_CHECK_ERROR = 'ADMIN_CHECK_ERROR';

//User types
export const STORE_INSTITUTES = 'STORE_INSTITUTES';
export const CREATE_INSTITUTE = 'CREATE_INSTITUTE';
export const UPDATE_INSTITUTE = 'UPDATE_INSTITUTE';
export const DELETE_INSTITUTE = 'DELETE_INSTITUTE';
export const INSTITUTE_FETCH_ERROR = 'INSTITUTE_FETCH_ERROR';

export const STORE_LEADS = 'STORE_LEADS';
export const CREATE_LEAD = 'CREATE_LEAD';
export const UPDATE_LEAD = 'UPDATE_LEAD';
export const DELETE_LEAD = 'DELETE_LEAD';
export const LEAD_FETCH_ERROR = 'LEAD_FETCH_ERROR';

export const STORE_GAMES = 'STORE_GAMES';
export const STORE_GAME = 'STORE_GAME';
export const CREATE_GAME = 'CREATE_GAME';
export const UPDATE_GAME = 'UPDATE_GAME';
export const DELETE_GAME = 'DELETE_GAME';
export const GAME_FETCH_ERROR = 'GAME_FETCH_ERROR';

export const CREATE_USER = 'CREATE_USER';

// login types
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";