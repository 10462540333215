const IS_PROD = process.env.NODE_ENV === 'production';
// const API_URL = IS_PROD ? 'https://institute.game.eduzo.com/institute/api' : 'http://localhost:4000/api';
const API_URL = IS_PROD ? 'https://game.eduzo.com/admin/api' : 'http://localhost:4000/api';
// const API_URL =  'https://game.eduzo.com/admin/api' ;
// const API_URL =  'https://eduzo-admin-api.herokuapp.com/api' ;

const MAPBOX_URL = 'https://api.mapbox.com';
const MAPBOX_KEY = '';

export {
    IS_PROD,
    API_URL,
    MAPBOX_URL,
    MAPBOX_KEY
}
