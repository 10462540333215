import * as types from '../types';

const initialState = {
    loading: false,
    error: false,
    fetchGamesError: null,
    fetchGameError: null,
}

export const gameReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.STORE_GAMES:
            return {
                ...state,
                games: action.payload
            }

        case types.STORE_GAME:
            return {
                ...state,
                games: action.payload
                // [action.id]:{
                //     ...state[action.id],
                //     games: action.payload
                // }
            }

        case types.CREATE_GAME:
            return {
                ...state,
                // games: state.games ? [...state.games, action.payload] : undefined
                games:action.payload
            }

        case types.UPDATE_GAME:
            return {
                ...state,
                games: state.games ? state.games.map(u => u.id === +action.payload.id ? action.payload : u) : undefined
            }

        case types.DELETE_GAME:
            return {
                ...state,
                games: state.games.filter(u => u.id !== +action.payload)
            }

        case types.GAME_FETCH_ERROR:
            return {
                ...state,
                fetchGameError: action.payload
            }
        default:
            return state;
    }
}