import axios from "axios";
import swal from 'sweetalert2';
import {
 
    LOGOUT,
    LOGIN_FAIL,
    AUTH_ERROR,
    USER_LOADED,
    LOGIN_SUCCESS,
} from "../types";

import setAuthToken from '../utils/setAuthToken'
import {API_URL} from '../../config';


export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(`${API_URL}/owner/login`);
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const login_action = (email, password) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  
    const body = JSON.stringify({ email, password });

    try {
      var res = await axios.post(`${API_URL}/owner/login`, body, config)

      console.log(res)

      await dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch(loadUser());
    } catch (errors) {
          console.log(errors)
          swal.fire({
            title: 'Error',
            text: 'Incorrect Email or Password',
            icon: 'error',
            confirmButtonText: 'Ok'
        })
          dispatch({
            type: LOGIN_FAIL,
          });
      }
};


// export const verify_password = ( password) => async (dispatch) => {
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//     },
//   };


//   const body = JSON.stringify({ password });

//   console.log(body);

//   // try {
//     const res = await axios.post(`${API_URL}/owner/verify-password`, body, config);

//     console.log(res)

//   //   await dispatch({
//   //     type: LOGIN_SUCCESS,
//   //     payload: res.data,
//   //   });
//   //   dispatch(loadUser());
//   // } catch (errors) {
//   //       console.log(errors)

//   //       dispatch({
//   //         type: LOGIN_FAIL,
//   //       });
//   //   }
// };



export const logout = () => (dispatch) => {
  console.log('action')
  dispatch({
    type: LOGOUT,
  });
};