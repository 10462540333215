import {combineReducers} from 'redux';
import {instituteReducer} from './InstituteReducer';
import {gameReducer} from './GameReducer'
import {leadReducer} from './LeadReducer'
import {AuthReducer} from './AuthReducer'

export default combineReducers({
    Authentication:AuthReducer,
    institutes:instituteReducer,
    games:gameReducer,
    leads:leadReducer
})