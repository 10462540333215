import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';

import rootReducer from './reducers';
import {IS_PROD} from '../config';

// const initialState = {
//     admin:{
//         isLoggedIn:false,
//         loading:false,
//         error:false,
//     },};
// 

const store = createStore(rootReducer, {}, IS_PROD ? applyMiddleware(thunk) : composeWithDevTools(applyMiddleware(thunk)));

export default store;